<template>
    <main class="events-page">
        <h1 class="events-page__title">{{ events.page.h1 }}</h1>

        <template v-if="existsAnyEvent">
            <section class="events-page__section">
                <h2 class="subtitle">{{ $lang.pages.eventosIndex.active_events }}</h2>
                <ul v-if="events.page.data.active.data.length" class="event-list">
                    <li v-for="event of events.page.data.active.data" :key="event.id">
                        <CardEvent :event="event" />
                    </li>
                </ul>
                <MiscNoResults v-else mode="megaphone" :title="$lang.pages.eventosIndex.no_active_events" />
            </section>
            <section class="events-page__section">
                <h2 class="subtitle">{{ $lang.pages.eventosIndex.next_events }}</h2>
                <ul v-if="events.page.data.next.data.length" class="event-list">
                    <li v-for="event of events.page.data.next.data" :key="event.id">
                        <CardEvent :event="event" />
                    </li>
                </ul>
                <MiscNoResults v-else mode="megaphone" :title="$lang.pages.eventosIndex.not_events_found" />
            </section>
            <section class="events-page__section">
                <h2 class="subtitle">{{ $lang.pages.eventosIndex.missed_events }}</h2>
                <ul v-if="events.page.data.expired.data.length" class="event-list">
                    <li v-for="event of events.page.data.expired.data" :key="event.id">
                        <CardEvent :event="event" :expire="true" />
                    </li>
                </ul>
                <MiscNoResults v-else mode="megaphone" :title="$lang.pages.eventosIndex.no_expired_events" />
            </section>
        </template>
        <MiscNoResults
            v-else
            mode="megaphone"
            :title="$lang.pages.eventosIndex.no_discounts_active"
            class="mb-5"
        />

        <section class="events-page__section-description">
            <div class="events-page__subtitle">
                <img :src="$assets.primary.bag" />
                <h2>{{ $lang.pages.eventosIndex.save }}</h2>
            </div>
            <div class="events-page__text" v-html="events.page.content" />
        </section>
    </main>
</template>

<script lang="ts" setup>
import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { $lang } = useNuxtApp()
const { buildHeaders, baseUrl, endpoints } = useApiClient()

const { data: responseData, error } = await useAsyncData('forum-response-data', () =>
    $fetch<Api.Responses.Pages.Events>(endpoints.pages.events.path, {
        headers: buildHeaders(),
        baseURL: baseUrl,
        method: 'GET',
    }).catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

let events = responseData.value

const currentDates = currentDate()
const {
    public: {
        variant: {
            metaData: { siteName, siteOrigin },
        },
    },
} = useRuntimeConfig()

const [title, description, titleH1, titleH2] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [events.page.title || '', events.page.description || '', events.page.h1 || '', events.page.h2 || ''],
)
const Route = useRoute()

useSeoMeta({
    title: title,
    ogUrl: siteOrigin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})

const existsAnyEvent = computed(() => {
    return (
        events.page.data.active.data.length > 0 ||
        events.page.data.expired.data.length > 0 ||
        events.page.data.next.data.length > 0
    )
})
</script>

<style lang="postcss" scoped>
.events-page {
    @apply container mt-8 pb-8 lg:max-w-5xl;
    &__title {
        @apply mb-10;
    }
    &__section {
        @apply mb-8;
        .event-list {
            @apply mb-6 mt-6 grid grid-cols-1 gap-6 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4;
        }
        &-description {
            @apply mb-14;
        }
    }
    &__subtitle {
        @apply mb-2 flex items-start gap-2 md:items-center;
        h2 {
            @apply text-2xl lg:text-[28px];
        }
        img {
            @apply h-8 w-8;
        }
    }
    &__text {
        @apply mb-4 text-sm text-[#716D7C];
    }
}
</style>
